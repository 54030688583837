import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import Error from "../../components/Error";
import I18n from "../../components/I18n";
import Layout from "../../components/Layout/Layout";
import PhoneNumberField from "../../components/PhoneNumberField";
import SquareButton from "../../components/SquareButton/SquareButton";

import { updatePhoneNumber } from "../../api";
import { RECAPTCHA_ACTION, SEGMENT_EVENTS } from "../../constants";
import { useTracking } from "../../contexts/useTracking";
import { useUserLocation } from "../../contexts/useUserLocation";
import {
  displayError,
  getFormattedPhoneNumber,
  validators,
} from "../../helpers";
import { useReCaptcha } from "../../hooks/useReCaptcha";

const PhoneNumberScreen = ({ transitionTo }) => {
  const { country } = useUserLocation();
  const [error, setError] = useState(false);
  const { trackEvent } = useTracking();
  const { i18n, t } = useTranslation();
  const { initializeReCaptcha, getReCaptchaToken } = useReCaptcha(
    i18n.language,
  );

  const trackContinueButton = formData => {
    if (validators.phoneNumber(formData.phone_number)) {
      const phone_number = getFormattedPhoneNumber(formData?.phone_number);
      trackEvent(SEGMENT_EVENTS.ENTER_PHONE_CONTINUE_TAPPED, {
        phone_number,
      });
    }
  };

  const submitWithReCaptcha = ({ phone_number, recaptcha_token }) =>
    updatePhoneNumber({ phone_number, recaptcha_token })
      .then(({ data }) => transitionTo(data))
      .catch(apiError => {
        displayError(setError(apiError?.message));
      });

  const onSubmit = formData => {
    const phone_number = getFormattedPhoneNumber(formData?.phone_number);
    getReCaptchaToken({
      action: RECAPTCHA_ACTION,
      onReCaptchaTokenValidate: recaptcha_token =>
        submitWithReCaptcha({
          phone_number,
          recaptcha_token,
        }),
    });
  };

  useEffect(() => {
    if (!window?.grecaptcha) {
      initializeReCaptcha();
    }
  }, [initializeReCaptcha]);

  return (
    <Layout
      title={<I18n id="phoneNumber.title" />}
      subtitle={<I18n id="phoneNumber.helper" />}
    >
      {error && <Error error={error} />}

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <PhoneNumberField country={country} />

            <SquareButton
              disabled={submitting}
              isLoading={submitting}
              onClick={() => trackContinueButton(values)}
              type="submit"
              text={t("buttons.continue")}
              backgroundColor="rgb(77, 148, 255)"
            />
          </form>
        )}
      </Form>
    </Layout>
  );
};

PhoneNumberScreen.propTypes = {
  transitionTo: PropTypes.func.isRequired,
};

export default PhoneNumberScreen;
