import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";

import I18n from "../I18n";
import { getLogoutUrl } from "../../helpers";
import { S } from "./styles";

const RestartLink = ({ restart }) => {
  const history = useHistory();
  const restartFlow = () => {
    Sentry.addBreadcrumb({
      category: "action",
      message: `Restarting flow`,
      level: "warning",
    });

    history.push(getLogoutUrl());
  };

  return (
    <>
      {restart && (
        <S.RestartLink onClick={restartFlow}>
          <I18n id="buttons.restart" />
        </S.RestartLink>
      )}
    </>
  );
};

RestartLink.propTypes = {
  restart: PropTypes.bool.isRequired,
};

export default RestartLink;
