import { useEffect, useState } from "react";
import { getFromEnv } from "../helpers";

export const useReCaptcha = language => {
  const RECAPTCHA_SITE_KEY = getFromEnv("RECAPTCHA_SITE_KEY");
  const [init, setInitialize] = useState<boolean>(false);

  const initializeReCaptcha = () => {
    setInitialize(true);
  };

  const getReCaptchaToken = ({ action, onReCaptchaTokenValidate }) => {
    window?.grecaptcha?.enterprise?.ready(async () => {
      const token: string = await window.grecaptcha.enterprise.execute(
        RECAPTCHA_SITE_KEY,
        { action },
      );
      if (token) onReCaptchaTokenValidate(token);
    });
  };

  useEffect(() => {
    if (!window?.grecaptcha && init) {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}&hl=${language}`;
      document.body.appendChild(script);
    }
  }, [RECAPTCHA_SITE_KEY, init, language]);

  return {
    initializeReCaptcha,
    getReCaptchaToken,
  };
};
