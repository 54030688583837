import axios from "axios";

import { SSOWindow } from "../../@types";
import { getDeviceId, getUrlParams } from "../helpers";
import * as Sentry from "../services/Sentry";

declare let window: SSOWindow;
const { GATEWAY_ENDPOINT } = window.Heetch.env;
const GATEWAY_URL = GATEWAY_ENDPOINT ? new URL(GATEWAY_ENDPOINT) : null;

const client = "web";

export const deviceId = getDeviceId();
Sentry.setTag("device_id", deviceId);

export const httpClient = axios.create({
  baseURL: GATEWAY_URL?.origin,
  params: getUrlParams(GATEWAY_URL?.search),
  headers: {
    "Device-Id": deviceId,
    "Content-Type": "application/json",
  },
});

export const setAuthorizationToken = (token: string) => {
  httpClient.defaults.headers.common.Authorization = `Token ${token}`;
};

export const authenticateSession = ({ password }: { password: string }) =>
  httpClient.put("/session/authenticate", {
    password,
  });

export const createFacebookSession = ({
  access_token,
}: {
  access_token: string;
}) =>
  httpClient.post("/session/oauth/facebook", {
    client,
    access_token,
  });

interface AppleAuthRequestParams {
  code: string;
}
export const createAppleSession = (data: AppleAuthRequestParams) =>
  httpClient.post("/session/oauth/apple", {
    client,
    apple_client_id: "com.heetch.HeetchService",
    ...data,
  });

export const linkAppleSession = (data: AppleAuthRequestParams) =>
  httpClient.put("/session/oauth/apple/link", {
    client,
    apple_client_id: "com.heetch.HeetchService",
    ...data,
  });

export const createSession = ({
  phone_number,
  recaptcha_token,
}: {
  phone_number: string;
  recaptcha_token: string;
}) =>
  httpClient.post("/web/session", {
    client,
    phone_number,
    recaptcha_token,
  });

export const deleteSession = () => httpClient.delete("/session");

export const getSession = () => httpClient.get("/session");

export const linkFacebookSession = ({
  access_token,
}: {
  access_token: string;
}) =>
  httpClient.put("/session/oauth/facebook/link", {
    access_token,
  });

export const updatePhoneNumber = ({
  phone_number,
  recaptcha_token,
}: {
  phone_number: string;
  recaptcha_token: string;
}) =>
  httpClient.put("/web/session/phone_number", {
    phone_number,
    recaptcha_token,
  });

export const verifyPhoneNumber = ({
  verification_code,
}: {
  verification_code: string;
}) =>
  httpClient.put("/session/phone_number_verification", {
    verification_code,
  });

export const sendVerificationCode = ({
  recaptcha_token,
}: {
  recaptcha_token: string;
}) =>
  httpClient.put(
    "/web/session/phone_number_verification/send_verification_code",
    {
      medium: "sms",
      recaptcha_token,
    },
  );

export const recoverPassword = () =>
  httpClient.post("/session/password/initiate_recovery", {
    medium: "email",
  });

export const ssoRedirect = ({ redirect_uri }: { redirect_uri: string }) =>
  httpClient.post("/sso/redirect/verify", {
    redirect_uri,
  });

export const createUserAccount = (userData: any) =>
  httpClient.post("/session/user_account", userData);
