import { AuthState, AuthStateKey } from "../../@types";
import { getFromEnv } from "../helpers";
import Authenticated from "../screens/Authenticated";
import CreatePassword from "../screens/CreatePassword";
import EnterPassword from "../screens/EnterPassword";
import {
  NoAccountWithApple,
  NoAccountWithFacebook,
} from "../screens/NoAccountWithOAuth";
import NoAccountWithPhone from "../screens/NoAccountWithPhone";
import { AppleRecovery, FacebookRecovery } from "../screens/OAuthRecovery";
import PhoneNumber from "../screens/PhoneNumber";
import PhoneOrOAuth from "../screens/PhoneOrOAuth/PhoneOrOAuth";
import PhoneVerification from "../screens/PhoneVerification";
import Terminated from "../screens/Terminated";
import UserInformation from "../screens/UserInformation";

export const IS_DEV = process.env.NODE_ENV === "development";
export const IS_PROD = process.env.NODE_ENV === "production";
export const IS_STAGING = !!getFromEnv("KALYKE_CLUSTER_ID");

export const CONTACT_LINK =
  "https://help.heetch.com/hc/fr/requests/new?ticket_form_id=360000216520&request_type=driver____account____login_issue&section_id=360001654480";
export const EMAIL_CONTACT_LINK =
  "https://help.heetch.com/hc/fr/requests/new?ticket_form_id=360000001069";

export const PREFERRED_COUNTRIES = ["fr", "be", "dz", "ma", "ao"];

// eslint-disable-next-line no-unused-vars
export const STATES: { [k in AuthStateKey]: AuthState } = {
  AUTHENTICATED: "authenticated",
  AUTHENTICATION_REQUIRED: "authentication_required",
  CREATE_PASSWORD: "create_password",
  ENTER_PASSWORD: "enter_password",
  FACEBOOK_RECOVERY: "facebook_recovery",
  APPLE_RECOVERY: "apple_recovery",
  NO_ACCOUNT_WITH_PHONE: "NO_ACCOUNT_WITH_PHONE",
  NO_ACCOUNT_WITH_FACEBOOK: "NO_ACCOUNT_WITH_FACEBOOK",
  NO_ACCOUNT_WITH_APPLE: "NO_ACCOUNT_WITH_APPLE",
  PHONE_NUMBER: "phone_number_required",
  PHONE_NUMBER_VERIFICATION_REQUIRED: "phone_number_verification_required",
  PHONE_OR_OAUTH: "phone_or_oauth",
  TERMINATED: "terminated",
  USER_ACCOUNT_REQUIRED: "user_account_required",
  USER_INFORMATION: "user_information",
};

export const RECAPTCHA_ACTION = "signup";

export const SCREENS: { [state: string]: any } = {
  [STATES.AUTHENTICATED]: Authenticated,
  [STATES.CREATE_PASSWORD]: CreatePassword,
  [STATES.ENTER_PASSWORD]: EnterPassword,
  [STATES.FACEBOOK_RECOVERY]: FacebookRecovery,
  [STATES.APPLE_RECOVERY]: AppleRecovery,
  [STATES.NO_ACCOUNT_WITH_PHONE]: NoAccountWithPhone,
  [STATES.NO_ACCOUNT_WITH_FACEBOOK]: NoAccountWithFacebook,
  [STATES.NO_ACCOUNT_WITH_APPLE]: NoAccountWithApple,
  [STATES.PHONE_NUMBER]: PhoneNumber,
  [STATES.PHONE_NUMBER_VERIFICATION_REQUIRED]: PhoneVerification,
  [STATES.PHONE_OR_OAUTH]: PhoneOrOAuth,
  [STATES.TERMINATED]: Terminated,
  [STATES.USER_INFORMATION]: UserInformation,
};

export const SEGMENT_EVENTS = {
  ACCOUNT_DEACTIVATED_SHOWN: "account_deactivated_shown",
  BECOME_DRIVER_TAPPED: "become_driver_tapped",
  CODE_VERIFICATION_CONTINUE_TAPPED: "code_verification_continue_tapped",
  CODE_VERIFICATION_ISSUES_TAPPED: "code_verification_issues_tapped",
  CONNEXION_CONTINUE_TAPPED: "connexion_continue_tapped",
  CONNEXION_CONTINUE_WITH_FB_TAPPED: "connexion_continue_with_fb_tapped",
  CONNEXION_PAGE: "connexion_page",
  CREATE_PASSWORD_SHOWN: "create_password_shown",
  CREATE_PASSWORD_CONTINUE_TAPPED: "create_password_continue_tapped",
  ENTER_PASSWORD_SHOWN: "enter_password_shown",
  ENTER_PHONE_SHOWN: "enter_phone_shown",
  ENTER_PHONE_CONTINUE_TAPPED: "enter_phone_continue_tapped",
  APPLE_RECOVERY_ISSUES_TAPPED: "apple_recovery_issues_tapped",
  APPLE_RECOVERY_CONTINUE_TAPPED: "apple_recovery_continue_tapped",
  APPLE_SCRIPT_LOAD_ERROR: "apple-script-load-error",
  FACEBOOK_ISSUES_SHARED_PHONE_NUMBER_TAPPED:
    "facebook_issues_shared_phone_number_tapped",
  APPLE_ISSUES_SHARED_PHONE_NUMBER_TAPPED:
    "apple_issues_shared_phone_number_tapped",
  FACEBOOK_RECOVERY_CONTINUE_TAPPED: "facebook_recovery_continue_tapped",
  FACEBOOK_RECOVERY_ISSUES_CREATE_ACCOUNT_TAPPED:
    "facebook_recovery_issues_create_account_tapped",
  APPLE_RECOVERY_ISSUES_CREATE_ACCOUNT_TAPPED:
    "apple_recovery_issues_create_account_tapped",
  FACEBOOK_RECOVERY_ISSUES_TAPPED: "facebook_recovery_issues_tapped",
  FACEBOOK_RECOVERY_SHOWN: "facebook_recovery_shown",
  GOT_NO_SMS_SEND_AGAIN_TAPPED: "got_no_sms_send_again_tapped",
  GOT_NO_SMS_SHARED_PHONE_NUMBER_TAPPED:
    "got_no_sms_shared_phone_number_tapped",
  LOGGED_OUT: "logged_out",
  NO_ACCOUNT_WITH_PHONE: "no_account_with_phone",
  NO_FACEBOOK_ACCOUNT_SHOWN: "no_facebook_account_shown",
  PASSWORD_CONTINUE_TAPPED: "password_continue_tapped",
  PASSWORD_ISSUES_CREATE_ACCOUNT_TAPPED:
    "password_issues_create_account_tapped",
  PASSWORD_ISSUES_FORGOT_PASSWORD_TAPPED:
    "password_issues_forgot_password_tapped",
  PASSWORD_ISSUES_NOT_WORKING_TAPPED: "password_issues_not_working_tapped",
  PASSWORD_ISSUES_SHARED_PHONE_NUMBER_TAPPED:
    "password_issues_shared_phone_number_tapped",
  PASSWORD_ISSUES_TAPPED: "password_issues_tapped",
  SIGNED_UP: "signed_up",
  TRY_OTHER_ID_TAPPED: "try_other_id_tapped",
  USER_INFORMATION_SHOWN: "user_information_shown",
  USER_INFORMATION_CONTINUE_TAPPED: "user_information_continue_tapped",
  VERIFY_PHONE_NUMBER_SHOWN: "verify_phone_number_shown",
};

export const SCREEN_EVENT_NAME = {
  [STATES.CREATE_PASSWORD]: SEGMENT_EVENTS.CREATE_PASSWORD_SHOWN,
  [STATES.ENTER_PASSWORD]: SEGMENT_EVENTS.ENTER_PASSWORD_SHOWN,
  [STATES.FACEBOOK_RECOVERY]: SEGMENT_EVENTS.FACEBOOK_RECOVERY_SHOWN,
  [STATES.NO_ACCOUNT_WITH_PHONE]: SEGMENT_EVENTS.NO_ACCOUNT_WITH_PHONE,
  [STATES.NO_ACCOUNT_WITH_FACEBOOK]: SEGMENT_EVENTS.NO_FACEBOOK_ACCOUNT_SHOWN,
  [STATES.PHONE_NUMBER]: SEGMENT_EVENTS.ENTER_PHONE_SHOWN,
  [STATES.PHONE_NUMBER_VERIFICATION_REQUIRED]:
    SEGMENT_EVENTS.VERIFY_PHONE_NUMBER_SHOWN,
  [STATES.PHONE_OR_OAUTH]: SEGMENT_EVENTS.CONNEXION_PAGE,
  [STATES.USER_INFORMATION]: SEGMENT_EVENTS.USER_INFORMATION_SHOWN,
};

export const DEFAULT_DIRECT_URI = "https://www.heetch.com/";
